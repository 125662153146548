import calendarService from '../calendarService'

export default async function ExtractEventTitles (prefCulture) {
  const results = await calendarService.getCampCalendarEventTypesTranslations(prefCulture)
  if (results) {
    const newCalendarEventTypesTranslations = {}
    for (const [key, value] of Object.entries(results)) {
      newCalendarEventTypesTranslations[key.split('$')[1]] = value
    }
    return newCalendarEventTypesTranslations
  }
  return null
}
